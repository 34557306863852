import request from '@/utils/request'

const urlPrefix = '/roles'

export function getRole (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateRole (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteRole (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getRoleList (params) {
  return request.get(urlPrefix, { params })
}

export function createRole (data) {
  return request.post(urlPrefix, data)
}
