<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" placeholder="请输入角色名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-card :bordered="false">
      <div style="margin-bottom: 16px;">
        <a-button icon="plus" type="primary" @click="$refs.createForm.show()">创建</a-button>
        <create-form ref="createForm" @ok="() => { this.form.page = 1; this.fetch() }"></create-form>
      </div>

      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        row-key="id"
        :row-selection="rowSelection"
        :scroll="{ x: scrollX }"
        style="margin-bottom: 16px;"
      >
        <template slot="description" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
        </template>
        <template slot="operation" slot-scope="record">
          <a @click="$refs.permissionForm.show(record.id)">
            <a-space style="user-select: none;">
              <a-icon type="lock"></a-icon>
              <span>权限</span>
            </a-space>
          </a>
          <a-divider type="vertical"></a-divider>
          <a @click="$refs.updateForm.show(record.id)" style="color: #faad14;">
            <a-space style="user-select: none;">
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
          <a-divider type="vertical"></a-divider>
          <a-popconfirm ok-type="danger" title="是否确定删除？" @confirm="confirm(record.id)">
            <a-icon slot="icon" type="question-circle-o" style="color: #f5222d;"></a-icon>
            <a style="color: #f5222d;">
              <a-space style="user-select: none;">
                <a-icon type="delete"></a-icon>
                <span>删除</span>
              </a-space>
            </a>
          </a-popconfirm>
        </template>
      </a-table>
      <permission-form ref="permissionForm"></permission-form>
      <update-form ref="updateForm" @ok="fetch"></update-form>

      <div style="height: 32px;">
        <span v-if="rowSelection.selectedRowKeys.length" style="line-height: 32px;">{{ `已选中 ${rowSelection.selectedRowKeys.length} 条` }}</span>
        <a-pagination
          v-model="form.page"
          :pageSize="form.page_size"
          :show-size-changer="true"
          :show-total="t => `共 ${t} 条`"
          :total="total"
          @change="page => { this.form.page = page; this.fetch() }"
          @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.page_size = pageSize; this.fetch() }"
          style="float: right;"
        ></a-pagination>
      </div>
    </a-card>
  </div>
</template>

<script>
import {
  deleteRole,
  getRoleList
} from '@/api/role'
import CreateForm from './modules/CreateForm'
import PermissionForm from './modules/PermissionForm'
import UpdateForm from './modules/UpdateForm'

export default {
  name: 'RoleList',
  components: {
    CreateForm,
    PermissionForm,
    UpdateForm
  },
  data () {
    return {
      form: { page: 1, page_size: 10 },
      columns: [
        { dataIndex: 'name', title: '角色名称', width: 200 },
        { dataIndex: 'created_at', title: '创建时间', width: 200 },
        { dataIndex: 'description', title: '描述', width: 200, ellipsis: true, scopedSlots: { customRender: 'description' } },
        { title: '操作', fixed: 'right', width: 240, scopedSlots: { customRender: 'operation' } }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: selectedRowKeys => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      getRoleList(this.form).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    search () {
      this.form.page = 1
      this.rowSelection.selectedRowKeys = []
      this.fetch()
    },
    confirm (id) {
      deleteRole(id).then(res => {
        this.$message.success(res.message)
        if (this.total - 1 <= (this.form.page - 1) * this.form.pageSize) {
          if (this.form.page > 1) this.form.page -= 1
        }
        this.fetch()
      })
    }
  }
}
</script>
