<template>
  <a-modal
    :confirm-loading="loading"
    :dialog-style="{ top: '24px' }"
    title="角色权限"
    :visible="visible"
    :width="1080"
    @ok="ok"
    @cancel="visible = false"
  >
    <a-table
      :bordered="true"
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      :row-key="record => record.page + record.module"
      :scroll="{ x: scrollX }"
    >
      <template slot="checkAll" slot-scope="text, record, index">
        <a-checkbox
          :indeterminate="indeterminate[index]"
          :checked="checkAll[index]"
          @change="onCheckAllChange(record, index, $event)"
        >
          全选
        </a-checkbox>
      </template>
      <template slot="permission" slot-scope="text, record, index">
        <a-checkbox-group v-model="dataSource[index].permission" :options="options(record.module)" @change="onChange(record,index)">
        </a-checkbox-group>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { getRole, updateRole } from '@/api/role'

export default {
  name: 'PermissionForm',
  data () {
    return {
      loading: false,
      visible: false,
      columns: [
        // {
        //   dataIndex: 'module',
        //   title: '模块',
        //   width: 200,
        //   scopedSlots: { customRender: 'module' },
        //   customRender: (value, _, index) => {
        //     const data = { children: value, attrs: { rowSpan: 1 } }
        //     if (index === 0) data.attrs.rowSpan = 5
        //     if (index === 1) data.attrs.rowSpan = 0
        //     if (index === 2) data.attrs.rowSpan = 0
        //     if (index === 3) data.attrs.rowSpan = 0
        //     if (index === 4) data.attrs.rowSpan = 0
        //     if (index === 5) data.attrs.rowSpan = 2
        //     if (index === 6) data.attrs.rowSpan = 0
        //     if (index === 7) data.attrs.rowSpan = 1
        //     return data
        //   }
        // },
        { dataIndex: 'page', title: '页面', width: 300, scopedSlots: { customRender: 'page' } },
        { dataIndex: 'checkAll', title: '全选', width: 150, colSpan: 0, scopedSlots: { customRender: 'checkAll' } },
        { dataIndex: 'permission', title: '权限', colSpan: 2, scopedSlots: { customRender: 'permission' } }
      ],
      dataSource: [
        { page: '主机', permission: [] },
        { page: '接口', permission: [] },
        { page: '中间件', permission: [] },
        { page: '数据库', permission: [] },
        { page: '地址资源', permission: [] },
        { page: '告警分类', permission: [] },
        { page: '告警事件', permission: [] },
        { page: '系统', permission: [] }
      ],
      type: [
        'host',
        'web',
        'middleware',
        'database',
        'ip_subnet',
        'alert_category',
        'alert_event',
        'project'
      ],
      id: '',
      checkAll: [],
      indeterminate: []
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    }
  },
  methods: {
    options () {
      return [{ label: '查看', value: 'view' }]
    },

    getValues (arr) {
      const data = []
      arr.map(e => { data.push(e.value) })
      return data
    },
    ok () {
      const permission = {}
      this.type.forEach((item, i) => { permission[item] = this.dataSource[i].permission })
      const data = { permission }
      updateRole(this.id, data).then(res => {
        this.$message.success(res.message)
        this.$emit('ok')
        this.visible = false
      }).finally(() => {
        this.loading = false
      })
    },
    onChange (record, i) {
      this.indeterminate[i] = !!record.permission.length && record.permission.length < this.options(record.module).length
      this.checkAll.splice(i, 1, record.permission.length === this.options(record.module).length)
    },
    onCheckAllChange (record, i, e) {
      this.getValues(this.options(record.module))
      if (e.target.checked) {
        this.dataSource[i].permission.splice(0, this.dataSource[i].permission.length, ...this.getValues(this.options(record.module)))
      } else {
        this.dataSource[i].permission.splice(0, this.dataSource[i].permission.length)
      }
      this.indeterminate.splice(i, 1, false)
      this.checkAll.splice(i, 1, e.target.checked)
    },
    show (id) {
      this.id = id
      getRole(id).then(res => {
        const permission = res.data.permission || {}
        this.dataSource.map((e, i) => {
          e.permission = permission[this.type[i]] || []
          if (e.permission.length) {
            if (e.permission.length === this.options(e.module).length) {
              this.checkAll[i] = true
              this.indeterminate[i] = false
            } else {
              this.checkAll[i] = false
              this.indeterminate[i] = true
            }
          } else {
            this.checkAll[i] = false
            this.indeterminate[i] = false
          }
        })
      }).finally(() => {
        this.visible = true
      })
    }
  }
}

</script>
